.chat-container {
  padding: 30px !important;
  margin-top: 40px !important;
}

.AIchat {
  height: 68vh;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-behavior: smooth;
}

.AIchat::-webkit-scrollbar {
  display: none;
}

.navigate-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  margin-bottom: 50px !important;
}

.back-text {
  font-family: "DM Sans", sans-serif !important;
  font-size: 18px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  letter-spacing: 0px !important;
  text-align: center !important;
}

.helpText {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #989a9c !important;
  font-family: "DM Sans", sans-serif !important;
  font-size: 18px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  letter-spacing: 0px !important;
}

.user-details {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px !important;
  color: #fff;
}

.avatar {
  height: 31px !important;
  width: 31px !important;
  color: #000000 !important;
  font-family: "DM Sans", sans-serif !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  line-height: 18px !important;
  letter-spacing: 0px !important;
  text-align: center !important;
}

.user-name {
  font-family: "DM Sans", sans-serif !important;
  font-size: 22px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  letter-spacing: 0px !important;
}

.message-content {
  font-family: "DM Sans", sans-serif !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 25px !important;
  letter-spacing: 0px !important;
  padding-left: 40px !important;
  text-wrap: wrap !important;
}

.markdown p {
  line-height: 25px;
}
.markdown ol > li,
.markdown ul > li {
  margin: 10px 20px;
}
.markdown a {
  color: #d286ff;
}

.chat-container .textfield > .MuiInputBase-root {
  background-color: #1f2327;
  font-family: "DM Sans", sans-serif;
}

.chat-container .textfield > .MuiFilledInput-root:hover {
  background-color: #1f2327 !important;
}

.chat-container .textfield > .MuiFilledInput-input:focus {
  background-color: #1f2327 !important;
}

.chat-container .textfield > .MuiFilledInput-root.Mui-focused {
  border: 2px solid #d286ff !important;
}

.submit-btn {
  background-color: #d286ff !important;
  border-radius: 20px !important;
  height: 60px !important;
  margin-right: 5px !important;
}

/* chat loader*/
.chatloader {
  width: 30px;
  aspect-ratio: 2;
  --_g: no-repeat radial-gradient(circle closest-side, #d286ff 90%, #0000);
  background: var(--_g) 0% 50%, var(--_g) 50% 50%, var(--_g) 100% 50%;
  background-size: calc(100% / 3) 50%;
  animation: l3 1s infinite linear;
}
@keyframes l3 {
  20% {
    background-position: 0% 0%, 50% 50%, 100% 50%;
  }
  40% {
    background-position: 0% 100%, 50% 0%, 100% 50%;
  }
  60% {
    background-position: 0% 50%, 50% 100%, 100% 0%;
  }
  80% {
    background-position: 0% 50%, 50% 50%, 100% 100%;
  }
}

/* chat loader2 */
.chatloader2 {
  width: 20px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 3px solid;
  border-color: #fff #0000;
  animation: l1 1s infinite;
}
@keyframes l1 {
  to {
    transform: rotate(0.5turn);
  }
}
