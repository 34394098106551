  .pricing-container {
      font-family: "Heebo", sans-serif !important;
      padding: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      color: #fff !important;
  }

  .pricing-text {
      font-family: "Heebo", sans-serif !important;
      color: rgba(255, 255, 255, 0.5) !important;
      font-weight: 400 !important;
      line-height: 32px !important;
      letter-spacing: 0px !important;
      text-align: center !important;


  }

  .pricing-card {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      background-color: #1f2327 !important;
      gap: 25px !important;
      color: #ffffff !important;
      border-radius: 15px !important;
  }

  .pricing-card-pro-text {
      font-family: "Heebo", sans-serif !important;
      font-weight: 400 !important;
      line-height: 38px !important;
      letter-spacing: 0px !important;
      text-align: left !important;
      font-size: 22px !important;
  }

  .pricing-card-dollor-text {
      font-family: "Heebo", sans-serif !important;
      font-weight: 400 !important;
      line-height: 38px !important;
      letter-spacing: 0px !important;
      text-align: left !important;
      margin: 30px 0 !important;

  }

  .pricing-card-content-title {
      font-weight: 400 !important;
      line-height: 32px !important;
      letter-spacing: 0px !important;
      text-align: center !important;
  }

  .pricing-card-content-list {
      font-family: "Heebo", sans-serif !important;
      padding: 0px 18px;
      margin-top: 10px;
      font-weight: 400 !important;
      line-height: 38px !important;
      letter-spacing: 0px !important;
  }

  .pricing-card-content-list>li::marker {
      color: rgba(255, 255, 255, 0.5);
      font-size: 20px;
  }


  .pricing-card-btn,
  .subsc-btn {
      background-color: #d286ff !important;
      height: 81px !important;
      padding: 16px 0px !important;
      border-radius: 20px !important;
      text-transform: none !important;
      margin-top: 30px !important;
      color: #fff !important;
      font-size: 18px !important;
      font-weight: 400 !important;
      font-family: "Heebo", sans-serif !important;
  }


  .payment-container {
      font-family: "Heebo", sans-serif !important;

  }

  .paymentform-container {
      font-family: "Heebo", sans-serif !important;
  }



  .paymentform-title {
      font-family: "Heebo", sans-serif !important;
      font-size: 24px !important;
      font-weight: 400 !important;
      line-height: 32px !important;
      letter-spacing: 0px !important;
      text-align: left !important;
  }

  .paymentform-lebel {
      font-family: "Heebo", sans-serif !important;
      font-size: 18px !important;
      font-weight: 400 !important;
      line-height: 32px !important;
      letter-spacing: 0px !important;
      text-align: left;
      color: rgba(255, 255, 255, 0.5) !important;


  }


  .paymentform-textfield>.MuiInputBase-root {
      background-color: #1f2327;
      border-radius: 20px;
      height: 80px;
      font-family: "Heebo", sans-serif !important;
  }

  .paymentform-textfield>.MuiInputBase-root>.MuiInputBase-input {
      color: #ffffff;
      padding: 20px;
  }

  .paymentform-textfield>.MuiInputBase-root:hover {
      background-color: #1f2327 !important;
      border-radius: 20px;
      height: 80px;
  }

  .paymentform-textfield>.MuiFilledInput-root:focus {
      background-color: #1f2327 !important;
  }

  .paymentform-textfield>.MuiFilledInput-root {
      background-color: #1f2327 !important;
  }

  .paymentform-textfield>.MuiFilledInput-input:focus {
      background-color: #1f2327 !important;
  }

  .paymentform-textfield>.MuiFilledInput-root.Mui-focused {
      border: 2px solid #d286ff;
  }

  .paymentform-textfield>.MuiOutlinedInput-root.Mui-focused>.MuiOutlinedInput-notchedOutline {
      border-color: #D286FF;
      border-width: 2px;
  }

  .paymentform-textfield .MuiNativeSelect-icon {
      color: #fff;
      padding-right: 10px;
      width: 30px;
      height: 30px;
  }

  .paymentform-textfield .MuiNativeSelect-select>option {
      background-color: #1f2327 !important;
  }

  .paymentform-textfield .MuiNativeSelect-select:focus {
      background-color: #1f2327 !important;
  }

  .paymentform-confirmation-text {
      font-size: 18px !important;
      font-weight: 400 !important;
      line-height: 22px !important;
      letter-spacing: 0px !important;
      text-align: left !important;
      margin: 30px 0 !important;
      color: rgba(255, 255, 255, 0.5) !important;


  }

  /* subscriptionTable */

.subscrTable {
    width: 100%;
    padding: 30px;
  }
  
  .subscr-title {
    font-family: "Heebo", sans-serif !important;
    font-size: 24px !important;
    font-weight: 400 !important;
    line-height: 32px !important;
    letter-spacing: 0px !important;
    margin-bottom: 30px !important;
  }
  
  .subscrTable .MuiFormControlLabel-root .MuiFormControlLabel-label {
    font-family: "Heebo", sans-serif !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 32px !important;
    letter-spacing: 0px !important;
  }
  
  .month-text {
    color: #989a9c !important;
    font-family: "Heebo", sans-serif !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 32px !important;
    letter-spacing: 0px !important;
  }
  
  .subscrTable .MuiTableCell-head,
  .subscrTable .MuiTableCell-body {
    height: 100px;
    color: #989a9c;
    background-color: #1f2327;
    border: none !important;
    font-family: "Heebo", sans-serif !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 32px !important;
    letter-spacing: 0px !important;
    text-align: center !important;
  }
  
  .subscrTable .MuiTableCell-body {
    color: #fff !important;
  }
  
  .subscrTable .Mui-checked,
  .subscrTable .Mui-checked + .MuiSwitch-track {
    color: #d286ff !important;
  }
  