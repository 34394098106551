.footer {
    font-family: "Heebo", sans-serif !important;
}

.footer-menu-title {
    font-size: 22px;
    font-weight: 700;
    font-family: "Heebo", sans-serif !important;
}

.footer-menu-subTitles {
    font-size: 16px;
    font-weight: 300;
    line-height: 28px;
    letter-spacing: 0.800000011920929px;
    text-align: left;
}

.footer-menu-subTitles>a {
    color: #ffffff !important;
}

.footer-menu-search {
    padding: 32px 0;
}

.footer-textfield>.MuiInputBase-root {
    background-color: #1F2327;
    border-radius: 30px;
    border-color: #C4C4C4;
    height: 54px;
    width: 250px;
}

.footer-textfield>.MuiInputBase-root>.MuiInputBase-input {
    color: #ffffff;
    padding: 20px;
}

.footer-textfield>.MuiInputBase-root>.MuiOutlinedInput-notchedOutline {
    border-color: #C4C4C4;
    border-width: 1px;
}

.footer-textfield>.MuiInputBase-root:hover>.MuiOutlinedInput-notchedOutline {
    border-color: #C4C4C4 !important;
}

.footer-textfield>.MuiOutlinedInput-root.Mui-focused>.MuiOutlinedInput-notchedOutline {
    border-color: #C4C4C4;
}

.footer-search-icon {
    border: 1px solid #c4c4c4 !important;
    height: 54px;
    width: 54px;
    left: 2px;
}

.footer-search-icon>.MuiSvgIcon-root {
    color: #c4c4c4 !important;
}