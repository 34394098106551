.card-title {
    font-family: "Heebo", sans-serif !important;
    font-size: 22px !important;
    font-weight: 700 !important;
    line-height: 24px !important;
    letter-spacing: 0px !important;
}

.card-content {
    font-family: "Heebo", sans-serif !important;
    font-size: 18px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    letter-spacing: 0px !important;
    margin-top: 15px !important;
}

.cards-container {
    --rotate: 0deg;
    --translate: 0px, 0px;
    animation: card-anim 2s 1;
    transform: scale(1) translate(var(--translate)) rotate(var(--rotate));
}

@keyframes card-anim {
    from {
        transform: scale(0) translate(0) rotate(0deg);
    }

    50% {
        transform: scale(1) translate(var(--translate)) rotate(var((--rotate)-10));
    }

    to {
        transform: scale(1) translate(var(--translate)) rotate(var(--rotate));
    }
}

@media (max-width: 900px) {
    .cards-container {
        animation-name: none;
        transform: none;
    }
}