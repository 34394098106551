.feature-container {
    font-family: "Heebo", sans-serif !important;
    padding: 30px;
}

.feature-title {
    font-family: "DM Sans", sans-serif !important;
    font-size: 22px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    letter-spacing: 0px !important;
    text-align: left !important;
}
.key-feature-title {
    font-family: "DM Sans", sans-serif !important;
    font-size: 22px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    letter-spacing: 0px !important;
    text-align: center !important;
}

.feature-left-sub-text {
    font-family: "Heebo", sans-serif !important;
    font-weight: 800 !important;
    line-height: 38px !important;
    letter-spacing: 0px !important;
    text-align: left !important;
    text-transform: uppercase !important;

}

.feature-right-sub-text {
    font-family: "Heebo", sans-serif !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    letter-spacing: 0px !important;
    text-align: left !important;
    color: rgba(255, 255, 255, 0.5) !important;

}

.feature-middle-title {
    font-family: "DM Sans", sans-serif !important;
    font-size: 22px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    letter-spacing: 0px !important;
    text-align: center !important;

}

.feature-card {
    height: 250px;
    max-width: 436px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: #1F2327 !important;
    color: #ffffff !important;
    border-radius: 15px !important;
}

.feature-card-title {
    font-family: "Heebo", sans-serif !important;
    font-size: 18px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    letter-spacing: 0px !important;
    text-align: left !important;
    text-transform: capitalize !important;

}

.feature-card-content {
    font-family: "Heebo", sans-serif !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    letter-spacing: 0px !important;
    text-align: left !important;

}

.keyfeature-card{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: #1F2327 !important;
    color: #ffffff !important;
    border-radius: 15px !important;
}
