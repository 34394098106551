.btn-box {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px !important;
}

.create-btn {
  width: 281px;
  height: 81px;
  padding: 14px !important;
  font-size: 18px !important;
  border-radius: 20px !important;
  background-color: #d286ff !important;
  color: #fff !important;
  text-transform: none !important;
}

.presentation-header {
  display: flex;
  flex-direction: column;
  margin-bottom: 50px !important;
  gap: 40px !important;
}

.presentation-head {
  font-family: "DM Sans", sans-serif !important;
  font-size: 22px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  letter-spacing: 0px !important;
}

.presentation-subhead {
  font-family: "DM Sans", sans-serif !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  letter-spacing: 0px !important;
}

.modal-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  /* width: 540px; */
  height: 286px;
  background-color: #1f2327;
  color: #fff;
  box-shadow: 24;
  border-radius: 20px;
  border: none !important;
  gap: 17px;
}

.modal-box .MuiTypography-root {
  font-size: 18px !important;
  line-height: 18px !important;
  font-family: "DM Sans", sans-serif !important;
}

.CloseIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  border-radius: 50%;
  background-color: #090b0c4d;
}

.textfield-label {
  color: #989a9c;
  font-family: "DM Sans", sans-serif !important;
  font-size: 18px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  letter-spacing: 0px !important;
}

.presentation-form .textfield>.MuiInputBase-root>.MuiInputBase-input {
  color: #ffffff;
  padding: 20px;
}

.presentation-form .textfield>.MuiInputBase-root {
  background-color: #1f2327 !important;
  font-family: "DM Sans", sans-serif;
}

.presentation-form .textfield>.MuiFilledInput-root:hover {
  background-color: #1f2327 !important;
}

.presentation-form .textfield>.MuiFilledInput-root:focus {
  background-color: #1f2327 !important;
}

.presentation-form .textfield>.MuiFilledInput-root.Mui-focused {
  border: 2px solid #d286ff;
}

.presentation-form .MuiSvgIcon-root {
  color: #fff;
  font-size: 50px !important;
}

.presentation-form
  .textfield
  > .MuiInputBase-root
  input[type="number"]::-webkit-outer-spin-button,
.presentation-form
  .textfield
  > .MuiInputBase-root
  input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media screen and (max-width: 480px) {
  .btn-box {
    justify-content:center !important;
  }
}

.selectTextfield{
  height: 100px;
  color: #ffffff !important;
  background-color: #1f2327;
  border-radius: 20px !important;
  padding-left: 10px;
  font-family: "DM Sans", sans-serif !important;
}