.home-container {
  font-family: "Heebo", sans-serif !important;
}

/* Home Section 1 */

.home-arrow-logo {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.home-header {
  font-family: "Heebo", sans-serif !important;
  font-weight: 300 !important;
  line-height: 36px;
  letter-spacing: 0.800000011920929px;
}

.home-header-btn {
  background-color: #e86754;
  margin-top: 20px;
  border-radius: 100px !important;
  /* height: 75px !important; */
  color: #fff !important;
  border-style: solid !important;
  border-color: #fff !important;
  border-right: none !important;
  padding-left: 20px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.home-header-btn-text {
  font-family: "Heebo", sans-serif !important;
  font-size: 18px !important;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0px;
  text-align: center;
  text-transform: uppercase;
}

.home-header-btn-icon {
  border-style: solid !important;
  border-color: #fff !important;
  border-radius: 50%;
  width: 75px;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.5s;
}

.home-header-btn:hover .home-header-btn-icon svg {
  animation: arrow 700ms forwards;
}

@keyframes arrow {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.home-banner-text {
  font-family: "Heebo", sans-serif !important;
  text-align: center;
  font-weight: 700 !important;
  letter-spacing: 0em !important;
}

/* Home section 2 */
.home-section2 {
  margin-top: 30px !important;
}

/* Home Section 3 */
.home-section3 {
  margin-top: 50px !important;
}

.section3-content-box {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 30px;
}

.section3-title {
  font-family: "Heebo", sans-serif !important;
  font-weight: 700 !important;
  line-height: 28px !important;
  letter-spacing: 0px !important;
}

.section3-sub-text {
  margin-top: 20px !important;
  font-family: "Heebo", sans-serif !important;
  font-weight: 300 !important;
  line-height: 25px !important;
  letter-spacing: 0px !important;
}

.section3-btn-box {
  width: 160px;
  height: 54px;
  padding: 14px, 80.97px, 14px, 31px;
  border-radius: 25px;
  border: 1px;
  border: 1px solid #ffffff !important;
  border-right: none !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.section3-header-btn-text {
  font-family: "Heebo", sans-serif !important;
  width: 105px;
  font-size: 13px !important;
  font-weight: 700 !important;
  line-height: 26px !important;
  letter-spacing: 1.2999999523162842px;
  text-align: center !important;
  text-transform: uppercase !important;
}

.section3-header-btn-icon {
  border: 1px solid #fff;
  border-radius: 50%;
  width: 54px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.section3-circle {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  position: relative;
  top: 200px;
  left: -210px;

  /* From https://css.glass */
  background: rgba(0, 0, 0, 0.15);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(1.9px);
  -webkit-backdrop-filter: blur(1.9px);
  border: 1px solid rgba(256, 256, 256, 0.55);

  animation: grow 2s 1;
}

@keyframes grow {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.home-section4 {
  font-family: "Heebo", sans-serif !important;
  padding: 30px;
}

.section4-presentation-container {
  display: flex;
  gap: 10px;
  width: 100%;
  overflow-x: auto;
  scrollbar-width: thin;
  scrollbar-color: #131619 #ffffff;
}

.section4-presentation-box {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  padding: 30px;
}

.section4-main-card-title {
  font-family: "Heebo", sans-serif !important;
  font-weight: 800 !important;
  line-height: 38px !important;
  letter-spacing: 0px !important;
  text-align: left !important;
  padding: 30px;
}

.section4-main-card-text {
  font-family: "Heebo", sans-serif !important;
  font-weight: 300 !important;
  line-height: 25px !important;
  letter-spacing: 0px !important;
  text-align: left !important;
  color: #c4c4c4;
  padding: 30px;
}

.presentation-img {
  width: 100%;
  object-fit: contain;
}

/* ===== Scrollbar CSS ===== */
.section4-presentation-container::-webkit-scrollbar {
  width: 2px;
}

.section4-presentation-container::-webkit-scrollbar-track {
  background: #ffffff;
}

.section4-presentation-container::-webkit-scrollbar-thumb {
  background-color: #131619;
  border-radius: 5px;
  border: 2px solid #131619;
}

.home-section5 {
  padding: 30px;
}

.section5-title {
  font-family: "Heebo", sans-serif !important;
  font-weight: 400 !important;
  line-height: 68px !important;
  letter-spacing: -1.399999976158142px !important;
  text-align: left !important;
}

.section5-text {
  font-family: "Heebo", sans-serif !important;
  font-weight: 300 !important;
  line-height: 25px !important;
  letter-spacing: 0.800000011920929px !important;
}

.section6-content-box {
  min-height: 400px;
  border: 1px solid #c4c4c4;
  border-right: none;
  border-left: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.section6-content-text {
  font-family: "Heebo", sans-serif !important;
  font-size: 20px !important;
  font-weight: 400 !important;
  line-height: 30px !important;
  letter-spacing: 0px !important;
  text-align: left !important;
  padding: 30px !important;
}
.section6-content-center-text {
  font-family: "Heebo", sans-serif !important;
  font-weight: 300 !important;
  line-height: 25px !important;
  letter-spacing: 0px !important;
  text-align: center !important;
  padding:30px;
}

.section6-content-buttons {
  flex-grow: 1;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #c4c4c4;
  border-bottom: none;
}

.section6-create-btn {
  background-color: transparent;
  /* margin-top: 20px; */
  border-radius: 100px !important;
  /* height: 75px !important; */
  color: #fff !important;
  border-style: solid !important;
  border-color: #fff !important;
  /* border: 1px solid #ffffff !important; */
  border-right: none !important;
  padding-left: 20px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.section6-create-btn-text {
  font-family: "Heebo", sans-serif !important;
  font-size: 18px !important;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0px;
  text-align: center;
  text-transform: uppercase;
}

.section6-create-btn-icon {
  border-style: solid !important;
  border-color: #fff !important;
  border-radius: 50%;
  width: 75px;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 480px) {
  .home-section3 {
    margin-top: 10px !important;
  }
}
