.Notpage-container {
    padding: 30px;
    text-align: center;
    height: 100vh;
    flex-direction: column;
    display: flex;
    justify-content: center;
    font-family: "Heebo", sans-serif !important;
}


.Notpage-title {
    color: #fff !important;
    font-family: "Heebo", sans-serif !important;
    font-weight: 700 !important;
}

.Notpage-text {
    color: #777 !important;
    font-family: "Heebo", sans-serif !important;
    line-height: 2;
    font-weight: 500 !important;
    font-size: 16px !important;
}



.Notpage-btn {
    width: 250px !important;
    display: inline-block;
    padding: 10px 20px;
    background-color: #D286FF !important;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s;
    font-family: "Heebo", sans-serif !important;
}

.Notpage-btn:hover {
    background-color: #b861ea !important;
}