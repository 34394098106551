.nav-container {
    height: 80px;
    width: 100vw;
    padding: 12px 6px 12px 30px;
    position: fixed;
    top: 0;
    z-index: 15;
}

.nav-container.active {
    background-color: #2c2929;
    border-bottom: 2px solid #d286ff66;
}

.nav-container.inactive {
    background-color: transparent;
}

.nav-menu {
    font-family: "DM Sans", sans-serif !important;
}

.menu-inactive {
    color: rgba(255, 255, 255, 0.5);
    transition: color 800ms linear;
    position: relative;
    border-bottom: 2px solid transparent;
}

.menu-inactive::before {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 0;
    height: 2px;
    background-color: #fff;
    transition: width 800ms linear;
}

.menu-inactive:hover {
    color: #fff;
    /* font-weight: bolder; */
}

.menu-inactive:hover::before {
    width: 100%;
}

.menu-active {
    color: #ffffff;
    font-weight: bolder;
}

.nav-login-btn {
    width: 85px;
    padding: 18px, 24px, 18px, 24px !important;
    color: #ffffff !important;
    border: 1px solid #fff !important;
    border-radius: 30px !important;
    font-size: 16px !important;
    font-family: "DM Sans", sans-serif !important;
    font-weight: 400 !important;
    text-transform: capitalize !important;
}

.nav-login-btn>a,
.nav-signup-btn>a {
    color: #ffffff !important;
}

.nav-signup-btn {
    background-color: #d286ff !important;
    width: 140px;
    padding: 18px, 24px, 18px, 24px !important;
    color: #ffffff !important;
    border-radius: 30px !important;
    font-size: 16px !important;
    font-family: "DM Sans", sans-serif !important;
    font-weight: 400 !important;
    text-transform: capitalize !important;
}

.drawer-container {
    transition: ease-in-out 0.7s;
}

.drawer-container>.MuiPaper-root {
    background-color: #1f2327 !important;
    transition: ease-in-out 0.7s;
}

.sidemenu-inactive {
    color: rgba(255, 255, 255, 0.5);
}

.sidemenu-active {
    color: #ffffff;
    font-weight: bolder;
}

.sidemenu-inactive>li>div>.sidemenu-icon {
    color: rgba(255, 255, 255, 0.5);
}

.sidemenu-active>li>div>.sidemenu-icon {
    color: #ffffff;
    font-weight: bolder;
}

.drawer-login-btn,
.drawer-signup-btn {
    padding: 18px, 24px, 18px, 24px !important;
    color: #ffffff !important;
    border: 1px solid #fff !important;
    border-radius: 5px !important;
    font-size: 16px !important;
    font-family: "DM Sans", sans-serif !important;
    font-weight: 400 !important;
    text-transform: capitalize !important;
    width: 85%;
    background-color: #d286ff !important;
    margin: 0 7.5% !important;
}

.drawer-login-btn>a {
    color: #ffffff !important;
}

.drawer-menu-icon>.MuiSvgIcon-root {
    color: #c4c4c4;
}