.file-container{
    margin: auto;
    margin-top: 110px;
}

.file-TypoText{
    font-weight: 400 !important;
    font-size: 18px !important;
    font-family: "DM Sans", sans-serif !important;
    color: rgba(255, 255, 255, 0.5);
    text-align: start !important;
    margin-bottom: 10px !important;
}
.IconText{
  font-family: "Heebo", sans-serif !important;
font-size: 22px !important;
font-weight: 400 !important;
line-height: 18px !important;
}

.file-iconBox{
    height: 100%;
    width: 48%;
    border-radius: 20px;
    background-color: rgba(31, 35, 39, 1);
   color: rgba(255, 255, 255, 0.5);
   cursor: pointer;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   gap: 10px;
}
input[type=file]::file-selector-button {
  display: none;
}

input[type=file]::-webkit-file-upload-button {
  display: block;
  width: 0;
  height: 0;
  margin-left: -100%;
}

input[type=file]::-ms-browse {
  display: none;
}

.fileTextFiled>.MuiInputBase-root>.MuiInputBase-input {
color: #ffffff;
padding: 20px;
font-family: "DM Sans" ,sans-serif !important;
font-size: 18px !important;
font-weight: 400 !important;
line-height: 18px !important;
  }
  
  .fileTextFiled>.MuiInputBase-root {
    background-color: #1f2327;
    font-family: "DM Sans", sans-serif;
    border-radius: 15px;
  }
  
  .fileTextFiled>.MuiFilledInput-root:hover {
    background-color: #1f2327 !important;
  }
  
  .fileTextFiled>.MuiFilledInput-root:focus {
    background-color: #1f2327 !important;
  }
  .fileTextFiled>.MuiFilledInput-root::placeholder {
        color: rgba(255, 255, 255, 0.25) !important;
  }
  
  .fileTextFiled>.MuiFilledInput-root.Mui-focused {
    border: 2px solid #d286ff;
  }

  .textFeildLabel{
font-family: "DM Sans", sans-serif !important;
font-size: 18px !important;
font-weight: 400 !important;
line-height: 18px !important;
margin-bottom: 10px !important;
  }

  .fileSubmitBtn{
    width: 100%;
    height: 75px;
    text-transform: none !important;
    color: #fff !important;
    font-size: 18px !important;
    border-radius: 15px !important;
    background-color: rgba(210, 134, 255, 1) !important;
    margin-top: 15px !important;
  }
  .fileTextFiled input[type="file"] {
    color: rgba(255, 255, 255,0.50) !important;
  }
  
  .file-selected input[type="file"] {
    color: #fff !important;
  }

  .loader-container{
    position: absolute;
    top: 50%;
    left: 50%;
    z-index:1;
    width: 100%;
    transform: translate(-50%, -50%);
  }

  .file-addFile{
    color: #d286ff;
    cursor: pointer;
  }

  .file-headerBox{
   display: flex;
   justify-content: space-between;
   align-items: center;
  }
  .file-FileList{
    width: 100%;
    height: 75px;
    display: flex;
    padding: 1em;
    justify-content: space-between;
    align-items: center;
    border-radius: 1em;
    background-color: rgba(31, 35, 39, 1);
  }

  .file-boxContent{
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .del-icn,.down-icn{
    color: #FFFFFF;
  }
  .list-filetext{
    font-size: 18px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    font-family: "DM Sans", sans-serif !important;
    color: #FFFFFF;
  }