.backbtn-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  padding: 20px;
}

.back-text {
  font-family: "DM Sans", sans-serif;
  font-size: 18px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  letter-spacing: 0px !important;
  text-align: center !important;
}

.slide-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.slideImg {
  object-fit: cover;
  height: 167px;
  border-radius: 25px;
}

.slide-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  padding: 20px 0px;
}

.slide-name {
  font-family: "DM Sans", sans-serif !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  letter-spacing: 0px !important;
}

.slide-time {
  font-family: "DM Sans", sans-serif !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  letter-spacing: 0px !important;
  color: #ffffff80 !important;
}

.slide-img-box {
  position: relative;
  display: inline-block;
}

.iconBox,
.deleteIconBox {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  background-color: #d286ff;
  position: absolute;
  top: 15px;
  right: 18px;
  display: none !important;
  z-index: 1;
}

.slide-hover-icon {
  color: #ffffff;
  font-size: 20px !important;
}

.slide-img-box:hover .slideImg {
  border: 2px solid #fff;
  opacity: 0.5;
  cursor: pointer;
}

.slide-img-box:hover .iconBox,
.slide-img-box:hover .deleteIconBox {
  display: flex !important;
  cursor: pointer;
}

.no-present-text{
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.no-present-create-btn{
  padding: 10px;
  background: #d286ff;
  color: white;
  font-weight: 500;
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
  text-transform: none !important;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.23);
}

.no-present-create-btn:active {
  transform: translateY(1px);
}
