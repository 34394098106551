.root {
  background-color: #131619;
  color: #ffffff;
}

.d-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

a {
  text-decoration: none;
}

.textfield > .MuiInputBase-root {
  background-color: #1f2327;
  border-radius: 20px;
  height: 63px;
}

.textfield > .MuiInputBase-root > .MuiInputBase-input {
  color: #ffffff;
  padding: 20px;
}

.textfield
  > .MuiOutlinedInput-root.Mui-focused
  > .MuiOutlinedInput-notchedOutline {
  border-color: #d286ff;
  border-width: 2px;
}

.Error-text-title {
  font-family: "Heebo", sans-serif !important;
  color: #ffffff !important;
}
.Error-subtext-title {
  font-family: "Heebo", sans-serif !important;
  color: #ffffff !important;
  font-size: 15px !important;
}
.Error-subtext1-title {
  font-family: "Heebo", sans-serif !important;
  color: #ffffff !important;
  font-size: 15px !important;
}
/* Framer motion slide CSS */
/* .slide-in {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #0f0f0f;
    transform-origin: bottom;
}

.slide-out {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #0f0f0f;
    transform-origin: top;
} */

.errorField {
  color: red;
  text-align: start;
  font-size: 13px !important;
}

/* ===== Scrollbar CSS ===== */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #131619;
}

::-webkit-scrollbar-thumb {
  background-color: #d286ff;
  border-radius: 5px;
  border: 2px solid #d286ff;
}
