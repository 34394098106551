.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Roboto", sans-serif !important;
}

.google-btn,
.apple-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  height: 63px !important;
  margin: 10px !important;
  padding: 10px !important;
  font-size: 18px !important;
  border: none !important;
  border-radius: 20px !important;
  cursor: pointer;
  background-color: #1f2327 !important;
  color: #fff !important;
  text-transform: none !important;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.23);
}

.google-btn:active,
.apple-btn:active,
.signup-btn:active {
  transform: translateY(1px);
}

.terms>.MuiCheckbox-root {
  color: #818181;
}

.terms>.MuiButtonBase-root-MuiCheckbox-root.Mui-checked {
  color: #1976d2;
}

.signup-btn {
  /* margin: 10px !important; */
  padding: 14px !important;
  height: 63px !important;
  font-size: 18px !important;
  border: none !important;
  border-radius: 20px !important;
  cursor: pointer !important;
  background-color: #1F2327 !important;
  color: #fff !important;
  text-transform: none !important;
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 2px 3px, rgba(0, 0, 0, 0.23) 0px 2px 3px; */
  /* box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.23); */
  box-shadow: 1px 2px 2px 1px rgb(124 110 124 / 75%);
}

.login-link {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  font-size: 16px !important;
  color: #989a9c;
  font-weight: 500 !important;
  margin-top: 12px;
}

.login-link>span>a {
  color: #fff !important;
  font-size: 18px !important;
}

.terms {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px;
}