/* MiniDrwer */

.sidebar-container {
  display: flex;
  position: relative;
}

/* .sidebar-container:hover>.MuiDrawer-root>.MuiDrawer-paper {
  border-right: 1px solid #d286ff !important;
} */

.sidebar-container:hover>.sidemenu-circle {
  display: flex !important;
}

.sidebar-container>.MuiDrawer-root>.MuiDrawer-paper {
  border-color: #d286ff !important;
  border-right: 1px solid #d286ff !important;
}

.drawerhead {
  display: flex;
  justify-content: space-between !important;
  margin-bottom: 20px;
}

.sidebar-header {
  padding: 15px !important;
  justify-content: center !important;
}

.sidebar-divider {
  background-color: #d286ff;
  width: 100%;
  margin: 0 3%;
  transition: ease-in-out 0.7s;
}

.menu-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 150px !important;
}

.listText {
  padding-left: 15px;
}

.listText-logout {
  padding-left: 30px;
}

.listText>.MuiListItemText-primary,
.listText-logout>.MuiListItemText-primary {
  font-family: "DM Sans" !important;
  font-size: 18px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  letter-spacing: 0px !important;
}

.sidemenu-list {
  display: flex;
  gap: 20px;
}

.sideMenu-inactive {
  color: rgba(255, 255, 255, 0.5);
}

.menu-active {
  color: #ffffff;
}

.sidemenu-circle {
  background: #36393f;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  justify-content: center;
  display: none;
  z-index: 2000;
  top: 26px;
  /* right: -15px; */
  position: fixed;
  border: solid #d286ff 2px;
}

.logout-btn {
  background-color: #d286ff !important;
  width: 250px;
  padding: 18px, 24px, 18px, 24px !important;
  margin: 5px 10px !important; 
  font-family: "DM Sans", sans-serif !important;
  font-weight: 400 !important;
  text-transform: capitalize !important;
  color: #ffffff !important;
  border: 1px solid #fff !important;
  border-radius: 5px !important;
  font-size: 16px !important;   
  
}