.subscr-card-cantainer {
  font-family: "Heebo", sans-serif !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  padding: 30px;
}

.subscr-card {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  background-color: #1f2327 !important;
  color: #ffffff !important;
  border-radius: 15px !important;
  height: 450px;
}

.subscr-card-pro-text {
  font-family: "Heebo", sans-serif !important;
  font-weight: 400 !important;
  line-height: 38px !important;
  letter-spacing: 0px !important;
  text-align: left !important;
  font-size: 22px !important;
}

.subscr-card-dollor-text {
  font-family: "Heebo", sans-serif !important;
  font-weight: 400 !important;
  line-height: 38px !important;
  letter-spacing: 0px !important;
  text-align: left !important;
  margin: 30px 0 !important;
}

.subscr-card-content-title {
  font-weight: 400 !important;
  line-height: 32px !important;
  letter-spacing: 0px !important;
  text-align: center !important;
}

.subscr-card-content-list {
  font-family: "Heebo", sans-serif !important;
  padding: 0px 18px;
  margin-top: 10px;
  font-weight: 400 !important;
  line-height: 38px !important;
  letter-spacing: 0px !important;
}

.subscr-card-content-list > li::marker {
  color: rgba(255, 255, 255, 0.5);
  font-size: 20px;
}

.subscr-card-btn {
  background-color: #d286ff !important;
  height: 81px !important;
  padding: 16px 0px !important;
  border-radius: 20px !important;
  text-transform: none !important;
  margin-top: 30px !important;
  color: #fff !important;
  font-size: 18px !important;
  font-weight: 400 !important;
  font-family: "Heebo", sans-serif !important;
}

/* subscriptionTable */

.subscrTable {
  width: 100%;
  padding: 30px;
}

.subscr-title {
  font-family: "Heebo", sans-serif !important;
  font-size: 24px !important;
  font-weight: 400 !important;
  line-height: 32px !important;
  letter-spacing: 0px !important;
  margin-bottom: 30px !important;
}

.subscrTable .MuiFormControlLabel-root .MuiFormControlLabel-label {
  font-family: "Heebo", sans-serif !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 32px !important;
  letter-spacing: 0px !important;
}

.month-text {
  color: #989a9c !important;
  font-family: "Heebo", sans-serif !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 32px !important;
  letter-spacing: 0px !important;
}

.subscrTable .MuiTableCell-head,
.subscrTable .MuiTableCell-body {
  height: 100px;
  color: #989a9c;
  background-color: #1f2327;
  border: none !important;
  font-family: "Heebo", sans-serif !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 32px !important;
  letter-spacing: 0px !important;
  text-align: center !important;
}

.subscrTable .MuiTableCell-body {
  color: #fff !important;
}

.subscrTable .Mui-checked,
.subscrTable .Mui-checked + .MuiSwitch-track {
  color: #d286ff !important;
}

/* payment loader */
.paymentLoader {
  width: 30px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 3px solid;
  border-color: #fff #0000;
  animation: l1 1s infinite;
}
@keyframes l1 {
  to {
    transform: rotate(0.5turn);
  }
}

.modal-box2 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  /* width: 540px; */
  height: 286px;
  background-color: #1f2327;
  color: #fff;
  box-shadow: 24;
  border-radius: 20px;
  border: none !important;
  gap: 17px;
}

.modal-text{
  font-family: "DM Sans", sans-serif !important;
  font-weight: 400 !important;
  line-height: 30px !important;
  letter-spacing: 0px !important;
  text-align: center !important;
  padding: 0px 20px;
}

.modal-content {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap:20px;
}

.confirm-btn , .close-btn{
  width: 120px;
  height: 45px;
  font-size: 16px !important;
  color: #fff !important;
  font-family: "Heebo", sans-serif !important;
  text-transform: none !important;
}

.confirm-btn{
  background-color: red !important;
}
.close-btn{
  background-color: #d286ff !important;
}
